import React from 'react';

export default function climateInfoTool() {
  return (
    <div>
      <iframe
        style={{ width: '100%', height: '100vh' }}
        title="cit"
        src="https://fao-climate-info-tool.firebaseapp.com/"
        frameBorder="0"
      />
    </div>
  );
}
